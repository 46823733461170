import Box from '@mui/material/Box';
import useAuth from '@hooks/useAuth';
import { useDepositeBalance } from '~/API/queryRequests/queryBalanse';
import { useTranslation } from 'react-i18next';
import BalanceItem from './BalanceItem';

const BalanceBlock = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { balance } = useDepositeBalance(user.merchantId);
  return (
    <Box>
      <BalanceItem
        title={t('Available balance')}
        amount={balance.balance}
        sxSubtytle1={{ fontWeight: '600' }}
      />
      <BalanceItem
        title={t('Own funds')}
        amount={balance.own_funds}
        sxSubtytle1={{ marginLeft: '10px' }}
      />
      <BalanceItem
        title={t('Overdraft')}
        amount={balance.overdraft}
        sxSubtytle1={{ marginLeft: '10px' }}
      />
      <BalanceItem
        title={t('used overdraft')}
        amount={balance.usedOverdraft}
        sxSubtytle1={{ marginLeft: '10px' }}
      />
      <BalanceItem
        title={t('Accepted payments')}
        amount={balance.accepted_payments}
        sxSubtytle1={{ fontWeight: '600' }}
      />
      <BalanceItem
        title={t('Payments')}
        amount={balance.payments}
        sxSubtytle1={{ fontWeight: '600' }}
      />
      <BalanceItem
        title={`- ${t('using own funds')}`}
        amount={balance.using_own_funds}
      />
      <BalanceItem
        title={`- ${t('using accepted payments')}`}
        amount={balance.using_accepted_payments}
      />
      <BalanceItem
        title={`- ${t('using overdraft')}`}
        amount={balance.using_overdraft}
      />
    </Box>
  );
};

export default BalanceBlock;
