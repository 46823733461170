import { experimentalStyled } from '@mui/material/styles';

const LogoRoot = experimentalStyled('svg')``;
function Logo(props) {
  return (
    <LogoRoot
      width="2580"
      height="511"
      viewBox="0 0 2580 511"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M54.1302 99.6972C81.8164 99.6972 104.26 77.3792 104.26 49.8486C104.26 22.318 81.8164 0 54.1302 0C26.4441 0 4 22.318 4 49.8486C4 77.3792 26.4441 99.6972 54.1302 99.6972Z"
        fill="white"
      />
      <path
        d="M54.1302 300.849C81.8164 300.849 104.26 278.531 104.26 251C104.26 223.469 81.8164 201.151 54.1302 201.151C26.4441 201.151 4 223.469 4 251C4 278.531 26.4441 300.849 54.1302 300.849Z"
        fill="white"
      />
      <path
        d="M54.1302 502C81.8164 502 104.26 479.682 104.26 452.151C104.26 424.621 81.8164 402.303 54.1302 402.303C26.4441 402.303 4 424.621 4 452.151C4 479.682 26.4441 502 54.1302 502Z"
        fill="white"
      />
      <path
        d="M254.841 99.6972C282.527 99.6972 304.971 77.3792 304.971 49.8486C304.971 22.318 282.527 0 254.841 0C227.155 0 204.711 22.318 204.711 49.8486C204.711 77.3792 227.155 99.6972 254.841 99.6972Z"
        fill="#00D3FF"
      />
      <path
        d="M254.841 300.849C282.527 300.849 304.971 278.531 304.971 251C304.971 223.469 282.527 201.151 254.841 201.151C227.155 201.151 204.711 223.469 204.711 251C204.711 278.531 227.155 300.849 254.841 300.849Z"
        fill="#00D3FF"
      />
      <path
        d="M254.841 502C282.527 502 304.971 479.682 304.971 452.151C304.971 424.621 282.527 402.303 254.841 402.303C227.155 402.303 204.711 424.621 204.711 452.151C204.711 479.682 227.155 502 254.841 502Z"
        fill="#00D3FF"
      />
      <path
        d="M455.55 99.6972C483.236 99.6972 505.68 77.3792 505.68 49.8486C505.68 22.318 483.236 0 455.55 0C427.864 0 405.42 22.318 405.42 49.8486C405.42 77.3792 427.864 99.6972 455.55 99.6972Z"
        fill="#00D3FF"
      />
      <path
        d="M455.55 300.849C483.236 300.849 505.68 278.531 505.68 251C505.68 223.469 483.236 201.151 455.55 201.151C427.864 201.151 405.42 223.469 405.42 251C405.42 278.531 427.864 300.849 455.55 300.849Z"
        fill="#00D3FF"
      />
      <path
        d="M455.55 502C483.236 502 505.68 479.682 505.68 452.151C505.68 424.621 483.236 402.303 455.55 402.303C427.864 402.303 405.42 424.621 405.42 452.151C405.42 479.682 427.864 502 455.55 502Z"
        fill="white"
      />
      <path
        d="M2348.44 510.291V464.505H2399.31C2403.72 464.505 2407.45 462.81 2410.5 459.418C2413.56 456.366 2415.59 452.974 2416.61 449.243L2424.75 426.351L2320.46 154.182H2373.88L2447.64 347.498H2457.82L2523.95 154.182H2577.37L2465.45 467.049C2460.02 481.972 2452.56 492.824 2443.06 499.607C2433.9 506.73 2420.17 510.291 2401.86 510.291H2348.44Z"
        fill="white"
      />
      <path
        d="M2254.01 408.545L2248.92 380.565H2246.38C2241.29 387.009 2235.18 392.605 2228.06 397.353C2211.78 408.206 2192.45 413.633 2170.07 413.633C2142.26 413.633 2120.04 406.341 2103.42 391.757C2086.8 377.174 2078.5 358.181 2078.5 334.78C2078.5 308.665 2087.14 288.147 2104.44 273.224C2122.08 258.301 2148.19 250.84 2182.78 250.84H2246.38V245.753C2246.38 229.134 2241.63 216.077 2232.13 206.581C2222.63 197.084 2209.58 192.336 2192.96 192.336C2176.68 192.336 2164.13 196.067 2155.31 203.528C2146.5 210.65 2141.24 218.79 2139.54 227.947H2088.67C2090.03 205.563 2100.03 186.91 2118.68 171.987C2137.34 156.725 2162.1 149.094 2192.96 149.094C2225.86 149.094 2251.46 157.743 2269.78 175.04C2288.09 192.336 2297.25 215.907 2297.25 245.753V408.545H2254.01ZM2246.38 301.713V291.538H2185.33C2166.67 291.538 2152.6 295.269 2143.1 302.73C2133.95 310.191 2129.37 320.027 2129.37 332.236C2129.37 344.107 2133.27 353.433 2141.07 360.216C2148.87 366.999 2160.23 370.391 2175.15 370.391C2198.22 370.391 2215.85 364.456 2228.06 352.585C2240.27 340.376 2246.38 323.418 2246.38 301.713Z"
        fill="white"
      />
      <path
        d="M1842.14 230.491H1946.43C1961.01 230.491 1973.56 224.556 1984.07 212.685C1994.58 200.815 1999.84 185.553 1999.84 166.9C1999.84 148.247 1994.58 132.985 1984.07 121.114C1973.56 109.244 1961.01 103.309 1946.43 103.309H1842.14V230.491ZM1788.72 408.545V52.4363H1948.97C1978.81 52.4363 2003.57 63.1196 2023.24 84.4861C2043.25 105.513 2053.26 132.985 2053.26 166.9C2053.26 200.815 2043.25 228.456 2023.24 249.822C2003.57 270.85 1978.81 281.364 1948.97 281.364H1842.14V408.545H1788.72Z"
        fill="white"
      />
      <path
        d="M1618.18 355.129V202.511H1577.48V154.182H1618.18V82.9599H1669.05V154.182H1727.55V202.511H1669.05V347.498C1669.05 357.673 1674.14 362.76 1684.31 362.76H1727.55V408.545H1674.14C1656.16 408.545 1642.26 403.797 1632.42 394.301C1622.92 384.805 1618.18 371.747 1618.18 355.129Z"
        fill="white"
      />
      <path
        d="M1500.85 408.545L1495.76 380.565H1493.22C1488.13 387.009 1482.03 392.605 1474.91 397.353C1458.63 408.206 1439.3 413.633 1416.91 413.633C1389.1 413.633 1366.89 406.341 1350.27 391.757C1333.65 377.174 1325.34 358.181 1325.34 334.78C1325.34 308.665 1333.99 288.147 1351.29 273.224C1368.92 258.301 1395.04 250.84 1429.63 250.84H1493.22V245.753C1493.22 229.134 1488.47 216.077 1478.98 206.581C1469.48 197.084 1456.42 192.336 1439.8 192.336C1423.53 192.336 1410.98 196.067 1402.16 203.528C1393.34 210.65 1388.08 218.79 1386.39 227.947H1335.52C1336.87 205.563 1346.88 186.91 1365.53 171.987C1384.18 156.725 1408.94 149.094 1439.8 149.094C1472.7 149.094 1498.31 157.743 1516.62 175.04C1534.94 192.336 1544.09 215.907 1544.09 245.753V408.545H1500.85ZM1493.22 301.713V291.538H1432.17C1413.52 291.538 1399.45 295.269 1389.95 302.73C1380.79 310.191 1376.21 320.027 1376.21 332.236C1376.21 344.107 1380.11 353.433 1387.91 360.216C1395.71 366.999 1407.08 370.391 1422 370.391C1445.06 370.391 1462.7 364.456 1474.91 352.585C1487.12 340.376 1493.22 323.418 1493.22 301.713Z"
        fill="white"
      />
      <path
        d="M1139.66 408.545L1050.63 154.182H1104.04L1172.72 352.585H1182.9L1251.58 154.182H1304.99L1215.96 408.545H1139.66Z"
        fill="white"
      />
      <path
        d="M963.906 408.545V154.182H1014.78V408.545H963.906ZM961.362 105.853V52.4363H1017.32V105.853H961.362Z"
        fill="white"
      />
      <path
        d="M722.181 408.545L597.543 52.4363H656.047L757.792 347.498H767.967L869.712 52.4363H928.216L803.577 408.545H722.181Z"
        fill="white"
      />
    </LogoRoot>
  );
}

export default Logo;
