import Loadable from '@root/routes/Loadable';
import { lazy } from 'react';

const FmPageSanctions = Loadable(lazy(() => import('@pages/fm/fmSanctions')));
const FmPageTerrorists = Loadable(lazy(() => import('@pages/fm/fmTerrorists')));
const FmPageSolutions = Loadable(lazy(() => import('@pages/fm/fmSolutions')));

export const fmRoute = {
  path: 'fm',
  children: [
    {
      path: 'sanctions',
      element: <FmPageSanctions />
    },
    {
      path: 'solutions',
      element: <FmPageSolutions />
    },
    {
      path: 'terrorists',
      element: <FmPageTerrorists />
    }
  ]
};
