import { lazy } from 'react';
import ACLGuard from '@comp/ACLGuard';

const FinMonIndexPage = lazy(() => import('@pages/FinMon'));
const FinMonUpdate = lazy(() => import('@pages/FinMon/FinMonUpdate'));

export const finMonRoute = {
  path: 'fin-mon',
  children: [
    {
      index: true,
      element: (
        <ACLGuard can="read">
          <FinMonIndexPage />
        </ACLGuard>
      )
    },
    {
      path: ':id/update',
      element: <FinMonUpdate can="update" />
    }
  ]
};
